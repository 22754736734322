import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

@Component({
  selector: 'mspot-card',
  template: ` <ng-content></ng-content> `,
  host: {
    '[class]': '"bg-dwhite shadow block " + classes()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CardComponent {
  hasBorder = input(true);
  mobileFull = input(false);

  classes = computed(() => {
    const borders = this.hasBorder() ? 'ring-1 card-ring-color' : '';
    const rounded = this.mobileFull() ? 'sm:rounded-lg' : 'rounded-lg';

    return `${borders} ${rounded}`;
  });
}
